<template>

	<van-space direction="vertical" fill style="margin-top: 3rem">
		<van-row>
			<van-image :src="require('@/assets/images/logotype-vertical-white.svg')"
					   fit="contain"
					   position="center"
					   width="35vw"
					   style="margin: 0 auto 1.25rem auto;" />
		</van-row>
		<van-form @submit="this.onSubmit" :set="has_error = !this.isEmpty(this.errors)">
			<van-notify v-model:show="has_error"
						type="danger"
						ref="notify"
						duration="3000">
				<span v-for="(error, index) in this.errors" :key="index">
					{{ error }}
				</span>
			</van-notify>
			<van-cell-group inset>
				<van-field v-model="this.form.email"
						   type="email"
						   name="email"
						   :label="this.translate('Email', this.lang)"
						   placeholder="yourname@example.com"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'email') }]"
				/>
				<van-field v-model="this.form.password"
						   type="password"
						   name="password"
						   :label="this.translate('Password', this.lang)"
						   placeholder="Password"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'password') }]"
				/>
			</van-cell-group>
			<div style="margin: 16px;">
				<van-button round
							block
							size="large"
							type="primary"
							color="linear-gradient(to right, #ff6034, #ee0a24)"
							class="van-shine"
							native-type="submit">
					{{ this.translate('Sign In', this.lang) }}
				</van-button>

				<van-button v-show="this.has_password_error"
							round
							block
							type="default"
							@click="this.toRoute('sign-up')"
							style="margin-top: 0.5rem">
					{{ this.translate('Forgot a password?', this.lang) }}
				</van-button>

				<van-divider :style="{ color: '#fff', borderColor: '#ffff', padding: '0 16px' }">{{ this.translate('or create account', this.lang) }}</van-divider>

				<van-button round
							block
							type="primary"
							class="van-shine"
							@click="this.toRoute('sign-up')">
					{{ this.translate('Sign Up', this.lang) }}
				</van-button>

				<van-divider :style="{ color: '#fff', borderColor: '#ffff', padding: '0 16px' }">{{ this.translate('or use', this.lang) }}</van-divider>

				<van-space direction="vertical" fill style="margin: 16px 0;">
					<van-button type="default"
								block
								round
								:loading-text="this.translate('Loading...', this.lang)"
								:icon="require('@/assets/images/google-small-logo.svg')"
								icon-prefix="brand-logo">
						<span style="margin-left: 0.5rem">{{ this.translate('Sign in with Google', this.lang) }}</span>
					</van-button>
					<van-button type="default"
								block
								round
								:loading-text="this.translate('Loading...', this.lang)"
								:icon="require('@/assets/images/yandex-small-logo.svg')"
								icon-prefix="brand-logo">
						<span style="margin-left: 0.5rem">{{ this.translate('Sign in with Yandex', this.lang) }}</span>
					</van-button>
					<van-button type="default"
								block
								round
								:loading-text="this.translate('Loading...', this.lang)"
								:icon="require('@/assets/images/apple-small-logo.svg')"
								icon-prefix="brand-logo">
						<span style="margin-left: 0.5rem">{{ this.translate('Sign in with Apple ID', this.lang) }}</span>
					</van-button>
					<van-button type="default"
								block
								round
								:loading-text="this.translate('Loading...', this.lang)"
								:icon="require('@/assets/images/facebook-small-logo.svg')"
								icon-prefix="brand-logo">
						<span style="margin-left: 0.5rem">{{ this.translate('Sign in with Facebook', this.lang) }}</span>
					</van-button>
					<van-button type="default"
								block
								round
								:loading-text="this.translate('Loading...', this.lang)"
								:icon="require('@/assets/images/vk-small-logo.svg')"
								icon-prefix="brand-logo">
						<span style="margin-left: 0.5rem">{{ this.translate('Sign in with VK', this.lang) }}</span>
					</van-button>
				</van-space>

				<div style="margin-top: 16px;font-size: 0.8rem;text-align: center;">
					{{ this.translate('By clicking "Sign In" or "Sign Up" you accept our', this.lang) }}
					<a href="javascript:{}">{{ this.translate('Terms of Use', this.lang) }}</a>.
					{{ this.translate('To find out how we process your personal data, please see our', this.lang) }}
					<a href="javascript:{}">{{ this.translate('Privacy Policy', this.lang) }}</a>
					{{ this.translate('and', this.lang) }}
					<a href="javascript:{}">{{ this.translate('Cookie Policy', this.lang) }}</a>.
				</div>
			</div>
		</van-form>
	</van-space>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useCurrentLang } from 'vant';
import CommonService from '@/services/CommonService.js';

export default defineComponent({
	name: "sign-in",
	data() {
		return {
			title: "Sign In",
			lang: 'en-US',
			translations: this.$root.intl.translations,
			form: {
				email: null,
				password: null,
				_errors: [],
			},
			errors: [],
			has_password_error: false
		};
	},
	components: {

	},
	mounted() {
		this.setPageTitle(this.title);
		this.lang = useCurrentLang();
	},
	methods: {
		setPageTitle(title) {
			CommonService.setPageTitle(title);
			this.emitter.emit("setPageTitle", title);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						return this.translations[lang][string];
					}
				}
			}

			return string;
		},
		inputValidate(value, input) {

			let message = null;

			if (input == 'email') {
				if (this.isEmpty(value)) {
					message = this.translate('Email is required', this.lang);
				} else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
					message = this.translate('Email syntax is not valid', this.lang);
				}
			}

			if (input == 'password') {
				if (this.isEmpty(value)) {
					message = this.translate('Password is required', this.lang);
				} else if (!(/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}/.test(value))) {
					message = this.translate('Password strength is not valid', this.lang);
				}
			}

			return message;
		},
		onSubmit() {
			console.info('onSubmit()');
			this.errors.push(this.translate('Password is not valid! Try again or restore it.', this.lang));
			this.has_password_error = true;
			setTimeout(() => {
				this.errors = [];
			}, 5000);
		}
	}
});
</script>